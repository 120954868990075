const imgCommonURL = 'https://www.kasikorncarabao.com/cdn/images/';

export const linkObsImage = {
  about: {
    kbankTD01: `${imgCommonURL}about/kbank-td-01.png`,
    kbankTD02: `${imgCommonURL}about/kbank-td-02.jpg`,
    kbankTD03: `${imgCommonURL}about/kbank-td-03.jpg`,
    kbankTD04: `${imgCommonURL}about/kbank-td-04.jpg`,
    kbankTD05: `${imgCommonURL}about/kbank-td-05.jpg`,
    imgTDxKBankInfo: `${imgCommonURL}about/td-kbank-info.jpg`,
  },
  campaign: {
    lineLogo: `${imgCommonURL}campaign/line-logo.png`,
    succeed: `${imgCommonURL}campaign/succeed.png`,
    banner: {
      bannerServiceDesktop: `${imgCommonURL}campaign/banner/service-desktop.png`,
      bannerServiceMobile: `${imgCommonURL}campaign/banner/service-mobile.png`,
    },
    largehero: {
      largeheroMobile: `${imgCommonURL}campaign/largehero/largehero-mobile.png`,
      largeheroDesktop: `${imgCommonURL}campaign/largehero/largehero-desktop.png`,
    },
    category: {
      frame1: `${imgCommonURL}campaign/category/Frame1.png`,
      frame2: `${imgCommonURL}campaign/category/Frame2.png`,
      frame3: `${imgCommonURL}campaign/category/Frame3.png`,
    },
    slide: {
      guarantor: `${imgCommonURL}campaign/slide/guarantor.png`,
      onGuarantor: `${imgCommonURL}campaign/slide/on-guarantor.png`,
    },
    step: {
      step1: `${imgCommonURL}campaign/step/step1.png`,
      step2: `${imgCommonURL}campaign/step/step2.png`,
      step3: `${imgCommonURL}campaign/step/step3.png`,
      step4: `${imgCommonURL}campaign/step/step4.png`,
      step5: `${imgCommonURL}campaign/step/step5.png`,
    },
  },
  campaignKbank: {
    largehero: {
      largeheroMobile: `${imgCommonURL}campaign-kbank/largehero/largehero-mobile.jpg`,
      largeheroDesktop: `${imgCommonURL}campaign-kbank/largehero/largehero-desktop.jpg`,
    },
  },
  contact: {
    imgLineKbao: `${imgCommonURL}contact/line-kbao2.png`,
  },
  home: {
    banner1: `${imgCommonURL}home/banner-1.png`,
    banner2: `${imgCommonURL}home/banner-2.png`,
    banner3: `${imgCommonURL}home/banner-3.png`,
    mbanner1: `${imgCommonURL}home/m-banner-1.png`,
    mbanner2: `${imgCommonURL}home/m-banner-2.png`,
    mbanner3: `${imgCommonURL}home/m-banner-3.png`,
  },
  searchBranches: {
    bannerMobile: `${imgCommonURL}search-branches/banner-mobile.png`,
    banner: `${imgCommonURL}search-branches/banner.png`,
    logoCJ: `${imgCommonURL}search-branches/logo-cj.png`,
    logoTD: `${imgCommonURL}search-branches/logo-td.png`,
    iconMobile: `${imgCommonURL}search-branches/icon-mobile.png`,
    iconElectric: `${imgCommonURL}search-branches/icon-electric.png`,
  },

  service: {
    appliance: {
      airConditioningDaikin: `${imgCommonURL}service/appliances/daikinAir.png`,
      airConditioningTcl: `${imgCommonURL}service/appliances/tclAir.png`,
      washingMachineToshiba: `${imgCommonURL}service/appliances/washingmachineToshiba.png`,
      refrigeratorMitsu: `${imgCommonURL}service/appliances/refrigeratorMitsubishi.png`,
    },
    mobiles: {
      A05: `${imgCommonURL}service/mobiles/A05.png`,
      A05s: `${imgCommonURL}service/mobiles/A05s.png`,
      A24: `${imgCommonURL}service/mobiles/A05s.png`,
      A34: `${imgCommonURL}service/mobiles/A34.png`,
      A18: `${imgCommonURL}service/mobiles/OppoA18.png`,
      A58: `${imgCommonURL}service/mobiles/OppoA58.png`,
      A98: `${imgCommonURL}service/mobiles/A98.png`,
      A78: `${imgCommonURL}service/mobiles/A78.png`,
      Y18: `${imgCommonURL}service/mobiles/VivoY18.png`,
      A3Pro: `${imgCommonURL}service/mobiles/A3Pro.png`,
      A3X: `${imgCommonURL}service/mobiles/A3X.png`,
      phoneRecommend: `${imgCommonURL}service/mobiles/phone-recommend.png`,
      phoneWebOPPOA3: `${imgCommonURL}service/mobiles/phone-web-OPPOA3.png`,
      phoneWebOPPOA3Pro5G: `${imgCommonURL}service/mobiles/phone-web-OPPOA3Pro5G.png`,
      phoneWebOPPOA3x: `${imgCommonURL}service/mobiles/phone-web-OPPOA3x.png`,
      phoneWebSSA05s: `${imgCommonURL}service/mobiles/phone-web-SSA05s.png`,
      phoneWebVIVOY19s: `${imgCommonURL}service/mobiles/phone-web-VIVOY19s.png`,
      phoneWeb: `${imgCommonURL}service/mobiles/phone-web.png`,
      phoneRecommendM: `${imgCommonURL}service/mobiles/phone-recommend-m.png`,
      phoneWebOPPOA3M: `${imgCommonURL}service/mobiles/phone-web-OPPOA3-m.png`,
      phoneWebOPPOA3Pro5GM: `${imgCommonURL}service/mobiles/phone-web-OPPOA3Pro5G-m.png`,
      phoneWebOPPOA3xM: `${imgCommonURL}service/mobiles/phone-web-OPPOA3x-m.png`,
      phoneWebSSA05sM: `${imgCommonURL}service/mobiles/phone-web-SSA05s-m.png`,
      phoneWebVIVOY19sM: `${imgCommonURL}service/mobiles/phone-web-VIVOY19s-m.png`,
      phoneWebM: `${imgCommonURL}service/mobiles/phone-web-m.png`,
    },
    icon: {
      powerful: `${imgCommonURL}service/icon/powerful.png`,
      selfhygiene: `${imgCommonURL}service/icon/selfhygiene.png`,
      quite: `${imgCommonURL}service/icon/quite.png`,
      airFilter: `${imgCommonURL}service/icon/airFilter.png`,
      filter: `${imgCommonURL}service/icon/filter.png`,
      airFlow: `${imgCommonURL}service/icon/airFlow.png`,
      warranty: `${imgCommonURL}service/icon/warranty.png`,
      evaporator: `${imgCommonURL}service/icon/evaporator.png`,
      laundry: `${imgCommonURL}service/icon/laundry.png`,
      dryingMachine: `${imgCommonURL}service/icon/dryingMachine.png`,
      quickDry: `${imgCommonURL}service/icon/quickDry.png`,
      backTime: `${imgCommonURL}service/icon/backTime.png`,
      processControl: `${imgCommonURL}service/icon/processControl.png`,
      antiVirus: `${imgCommonURL}service/icon/antiVirus.png`,
      cool: `${imgCommonURL}service/icon/cool.png`,
      mitsuWaranty: `${imgCommonURL}service/icon/mitsuWaranty.png`,
      iconPhone: `${imgCommonURL}service/icon/icon-phone.png`,
      iconScreen: `${imgCommonURL}service/icon/icon-screen.png`,
      iconRam: `${imgCommonURL}service/icon/icon-ram.png`,
      iconBaterry: `${imgCommonURL}service/icon/icon-baterry.png`,
    },
    logo: {
      aHome: `${imgCommonURL}service/logo/aHome.jpeg`,
      cjMore: `${imgCommonURL}service/logo/cj-more.jpeg`,
      samsung: `${imgCommonURL}service/logo/samsung.png`,
      haier: `${imgCommonURL}service/logo/haier.png`,
      mitsubishiElectric: `${imgCommonURL}service/logo/mitsubishi-electric.png`,
      oppo: `${imgCommonURL}service/logo/oppo.png`,
      daikin: `${imgCommonURL}service/logo/daikin.png`,
      carrier: `${imgCommonURL}service/logo/carrier.png`,
    },
    banners: {
      desktop: [
        `${imgCommonURL}service/banner/banner1.png`,
        `${imgCommonURL}service/banner/banner2.png`,
        `${imgCommonURL}service/banner/banner3.png`,
        `${imgCommonURL}service/banner/banner4.png`,
      ],
      mobile: [
        `${imgCommonURL}service/banner/banner1.png`,
        `${imgCommonURL}service/banner/banner2.png`,
        `${imgCommonURL}service/banner/banner3.png`,
        `${imgCommonURL}service/banner/banner4.png`,
      ],
    },
    lineLogo: `${imgCommonURL}campaign/line-logo.png`,
    howgood1: `${imgCommonURL}service/howgood1.png`,
    howgood2: `${imgCommonURL}service/howgood2.png`,
    howgood3: `${imgCommonURL}service/howgood3.png`,
    howgood4: `${imgCommonURL}service/howgood4.png`,
  },
  logo: `${imgCommonURL}logo.png`,
};
