import { linkObsImage } from '../../contentData/image-obs-link';

export const content = {
  skuContent: {
    price: 'ราคา',
    down: 'ดาวน์',
    downText: 'เริ่มต้นเพียง',
    installmentsText: 'ผ่อนงวดละ',
    installmentsTerm: 'x6 งวด',
    screenSize: 'ขนาดจอ',
    screen: 'จอ',
    interrestRate0: 'ดอกเบี้ย 0% ต่อปี',
    hypothesis: '*กู้เท่าที่จำเป็นและชำระคืนไหว',
  },
};

//------------------appliance------------------------
export const daikinMaxInverter = {
  title: 'เครื่องใช้ไฟฟ้า Daikin',
  urlImage: linkObsImage.service.appliance.airConditioningDaikin,
  imageHeight: 300,
  imageWidth: '100%',
  alignText: 'left',
  paddingLeftText: '55%',
  marginTopTextDesktop: -6,
  marginRightTextDesktop: 10,
  marginTopTextMobile: 12,
  downPrice: '4,019.-',
  installmentPrice: '1,960.-',
  installmentTerm: 'x12 งวด',
  skuName: 'Max Inverter',
  skuModel: 'FTKQ12XV2S/FTKQ18XV2S',
  specVal1: 'ขนาด BTU:',
  specVal2: '12,300 และ 18,100 BTU',
  features1: {
    icon: linkObsImage.service.icon.powerful,
    title: 'Powerful mode',
    description: 'ลมเย็นอย่างรวดเร็วภายใน 1 นาที',
  },
  features2: {
    icon: linkObsImage.service.icon.selfhygiene,
    title: 'Self-hygiene',
    description: 'โหมดทำความสะอาดคอยล์เย็น ลดการสะสมสิ่งสกปรกและเชื้อโรค',
  },
  features3: {
    icon: linkObsImage.service.icon.quite,
    title: 'Quite operation mode',
    description: 'ทำงานเงียบเสียงเบา และเงียบเพียง 40 dB(A)',
  },
  features4: {
    icon: linkObsImage.service.icon.airFilter,
    title: 'Air quality mode',
    description: 'ช่วยกรองฝุ่น PM 2.5 และช่วยยับยั้งแบคทีเรียด้วยสารชีวภาพ Enzyme Blue',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 27,539 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 12 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 4,019 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,960 บาทต่อเดือน ผ่อนนาน 12 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: true,
  price: '27,539.-',
};

export const tclNewMiracle = {
  title: 'เครื่องใช้ไฟฟ้า TCL',
  urlImage: linkObsImage.service.appliance.airConditioningTcl,
  imageHeight: 300,
  imageWidth: '100%',
  alignText: 'left',
  paddingLeftText: '55%',
  marginTopTextDesktop: -6,
  marginRightTextDesktop: 10,
  marginTopTextMobile: 12,
  downPrice: '2,999.-',
  installmentPrice: '1,320.-',
  installmentTerm: 'x12 งวด',
  skuName: 'New Miracle',
  skuModel: 'TAC-MFS13',
  specVal1: 'ขนาด BTU:',
  specVal2: '12,520 BTU',
  features1: {
    icon: linkObsImage.service.icon.filter,
    title: 'Filter Cleaning Reminder',
    description: 'การเตือนทำความสะอาดแผ่นกรองอัตโนมัติ',
  },
  features2: {
    icon: linkObsImage.service.icon.airFlow,
    title: 'Smart Air Flow',
    description: 'ใบพัดกระจายลมเย็นขยับ ขึ้น-ลง และ ซ้าย-ขวา อัตโนมัติ',
  },
  features3: {
    icon: linkObsImage.service.icon.evaporator,
    val1: 'Titan Gold',
    description: 'เคลือบคอยล์ร้อนและคอยล์เย็น สิทธิบัตรเฉพาะทีซีแอล',
  },
  features4: {
    icon: linkObsImage.service.icon.warranty,
    title: 'Warranty',
    description: 'รับประกันยาวนานคอมเพรสเซอร์รับประกัน 10 ปี อะไหล่ทั่วไปรับประกัน 5 ปี',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 18,839 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 12 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,999 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,320 บาทต่อเดือน ผ่อนนาน 12 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: true,
  price: '18,839.-',
};

export const toshibaTopload = {
  title: 'เครื่องใช้ไฟฟ้า Toshiba',
  urlImage: linkObsImage.service.appliance.washingMachineToshiba,
  imageHeight: 420,
  imageWidth: '100%',
  alignText: 'center',
  paddingLeftText: '0',
  marginTopTextDesktop: 2,
  marginRightTextDesktop: 0,
  marginTopTextMobile: 6,
  downPrice: '1,899.-',
  installmentPrice: '1,100.-',
  installmentTerm: 'x6 งวด',
  skuName: 'Top load 8 kg.',
  skuModel: 'AW-M901BT-WW',
  specVal1: 'ขนาดกิโลกรัม:',
  specVal2: '8 กิโลกรัม',
  features1: {
    icon: linkObsImage.service.icon.laundry,
    title: 'Soft Close Lid',
    description: 'ระหว่างการเปิด-ปิดฝาเครื่องใช้งานฝาจะปิดโดยการลดลงอย่างช้าๆ และปลอดภัย ไม่กระแทก',
  },
  features2: {
    icon: linkObsImage.service.icon.dryingMachine,
    title: 'พลังน้ำแรงสูง',
    description: 'ช่วยขจัดสิ่งสกปรกตกค้างในถังซัก ทั้งภายในและภายนอก',
  },
  features3: {
    icon: linkObsImage.service.icon.quickDry,
    title: 'ประหยัดน้ำ',
    description: 'คำนวณปริมาณน้ำอัตโนมัติตามน้ำหนักผ้าที่ซัก',
  },
  features4: {
    icon: linkObsImage.service.icon.backTime,
    title: 'ประหยัดเวลา',
    description: 'ด้วยโปรแกรมร่นเวลาซัก สำหรับผ้าน้อยชิ้น',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 8,499 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 1,899 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,100 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: false,
  price: '8,499.-',
};

export const mitsubishiFlatDesign = {
  title: 'เครื่องใช้ไฟฟ้า MITSUBISHI',
  urlImage: linkObsImage.service.appliance.refrigeratorMitsu,
  imageHeight: 420,
  imageWidth: '100%',
  alignText: 'center',
  paddingLeftText: '0',
  marginTopTextDesktop: 2,
  marginRightTextDesktop: 0,
  marginTopTextMobile: 6,
  downPrice: '2,129.-',
  installmentPrice: '1,510.-',
  installmentTerm: 'x6 งวด',
  skuName: 'Flat Design',
  skuModel: 'MR-FV22T-SL/MR-FV22T-BR',
  specVal1: 'ขนาดความจุ:',
  specVal2: '7.3 คิว',
  features1: {
    icon: linkObsImage.service.icon.processControl,
    title: '',
    description: 'ระบบปฎิบัติควบคุมการทำงาน สั่งการตรวจจับอุณหภูมิในจุดต่างๆ',
  },
  features2: {
    icon: linkObsImage.service.icon.antiVirus,
    title: '',
    description: 'ผนังช่องแช่ ผสมสารช่วยยับยั้งการเติบโตของเชื้อแบคทีเรียได้',
  },
  features3: {
    icon: linkObsImage.service.icon.cool,
    title: '',
    description: 'กระจายความเย็น ส่งความเย็นสู่ด้านหน้าประตู ได้เย็นเร็วทันใจ',
  },
  features4: {
    icon: linkObsImage.service.icon.mitsuWaranty,
    title: '',
    description: 'รับประกันยาวนาน คอมเพสเซอร์รับประกัน 10 ปี อะไหล่ภายในตัวเครื่องรับประกัน 2 ปี',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 11,189 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,129 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,510 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: false,
  price: '11,189.-',
};
