import { Box } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export interface Props {
  urlImage: string[];
}

const glassPanel = {
  margin: '5% auto',
};

export default function CarousalImageMobile(props: Props) {
  return (
    <>
      <Box className="slide-container">
        <Slide>
          {props.urlImage.map((slideImage, index) => (
            <Box key={index} style={glassPanel}>
              <img
                src={slideImage}
                width="100%"
                style={{
                  borderRadius: 4,
                }}
              />
            </Box>
          ))}
        </Slide>
      </Box>
    </>
  );
}
