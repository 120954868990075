export const serviceContent = {
  header: 'บริการของเรา',
  title: 'ผ่อนโทรศัพท์มือถือและเครื่องใช้ไฟฟ้ากับ kbao ผ่าน CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้',
  checkedVal1: 'บัตรประชาชนใบเดียว ก็สามารถสมัครได้ผ่านร้านค้าที่เข้าร่วม',
  checkedVal2: 'ผ่อนน้อย',
  checkedVal3: 'รู้ผลทันที',
  checkedVal4: 'ดอกเบี้ย 0% 6 เดือน (สำหรับแอร์  12 เดือน)',
  checkedVal5: 'กู้เท่าที่จำเป็น และชำระคืนไหว',
  remark:
    '*รุ่น Samsung A06 กรณีมีผู้ค้ำประกัน สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,069 บาท (คำนวณจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรก จำนวน 1,329 บาทที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 790 บาทต่อเดือน ผ่อนนาน  6 เดือน ทั้งนี้ การพิจารณาอนุมัติผลิตภัณฑ์สินเชื่อ และ เงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด / สินค้านี้จำหน่ายเฉพาะสาขาที่ร่วมรายการเท่านั้น / สินค้าซื้อแล้วไม่รับเปลี่ยน หรือ คืน / บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงรายการ และ ราคาที่ปรากฎในสิ่งพิมพ์ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า',

  mobileTile: 'โทรศัพท์มือถือ',
  store: 'ราคาสินค้า',

  GuarantorText: 'มีผู้ค้ำประกัน',
  NoGuarantorText: 'ไม่มีผู้ค้ำประกัน',

  faqTitle: 'คำถามที่พบบ่อย',
  brandProduct: 'แบรนด์สินค้าที่ร่วมรายการ',

  howGood: {
    title: 'ผ่อนมือถือที่ CJ MORE ดีอย่างไร?',
    subTitle: '(กู้เท่าที่จำเป็น และชำระคืนไหว)',
    text1: 'สมัครได้ทุกอาชีพ\nใช้บัตรประชาชนใบเดียว\nไม่ต้องมีสลิปเงินเดือน',
    text2: 'ผ่อน 0% นาน 6 เดือน\nดาวน์น้อย รับเครื่องทันที\nไม่จำเป็นต้องมีผู้ค้ำ\n(เครื่องใช้ไฟฟ้าผ่อนนาน 12 เดือน)',
    text3: 'ได้เครื่องแท้ 100%\nมีบริการหลังการขาย\nประกันศูนย์ไทย',
    text4: 'ไม่ติดสัญญา\nไม่ติดแพ็กเกจ\nใส่ซิมได้ทุกค่าย',
  },
  btn: {
    searchBranch: 'ค้นหาสาขา',
    addFriendLine: 'เพิ่มเพื่อน',
    link: 'https://lin.ee/35idgXY',
  },
};
