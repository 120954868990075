import { Box, Divider, Grid, Typography } from '@mui/material';
import BoxMobile from '../commons/ui/box-mobile';

import ApplianceContentBoxMobile from './appliance-content-box-m';
import { daikinMaxInverter, mitsubishiFlatDesign, tclNewMiracle, toshibaTopload } from './content-data-sku';
import FAQBoxContent from './faq-box-content';
import ButtonContent from './button-content';
import { TypeScreen } from '../../utils/enum/responsive-enum';
import { serviceContent } from '../../contentData/service';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { linkObsImage } from '../../contentData/image-obs-link';
import CarousalImageMobile from '../commons/ui/carousal-image-mobile';

export default function ServiceContentMobile() {
  const banners: string[] = linkObsImage.service.banners.mobile;

  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      let element: any = '';
      if (state.faq) {
        element = document.getElementById('box-faq');
      }
      element = element.getBoundingClientRect();
      const heightBox = element.top + window.scrollY || window.innerHeight;
      window.scrollTo({ top: heightBox, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state]);
  return (
    <>
      <BoxMobile>
        <CarousalImageMobile urlImage={banners} />
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: 500, fontSize: 18, color: '#433C3C' }}>
            {serviceContent.howGood.title}
          </Typography>
          <Typography
            component="div"
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: 400, fontSize: 12, color: '#433C3C' }}>
            {serviceContent.howGood.subTitle}
          </Typography>
        </Box>
        <Grid container>
          <Grid xs={6} justifyContent={'center'} display={'grid'} pl={2} pr={2} mt={2}>
            <img src={linkObsImage.service.howgood1} width={'100%'} style={{ maxWidth: '120px', maxHeight: '120px' }} />
          </Grid>
          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <img src={linkObsImage.service.howgood2} width={'100%'} style={{ maxWidth: '150px', maxHeight: '120px' }} />
          </Grid>
          <Grid xs={6} justifyContent={'center'} display={'grid'} pl={2} pr={2} mt={2}>
            <Typography
              component="div"
              variant="body2"
              align="center"
              sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
              {serviceContent.howGood.text1}
            </Typography>
          </Grid>
          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <Typography
              component="div"
              variant="body2"
              align="center"
              sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
              {serviceContent.howGood.text2}
            </Typography>
          </Grid>
          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <img src={linkObsImage.service.howgood3} width={'100%'} style={{ maxWidth: '120px', maxHeight: '105px' }} />
          </Grid>
          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <img src={linkObsImage.service.howgood4} width={'100%'} style={{ maxWidth: '115px', maxHeight: '105px' }} />
          </Grid>

          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <Typography
              component="div"
              variant="body2"
              align="center"
              sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
              {serviceContent.howGood.text3}
            </Typography>
          </Grid>
          <Grid xs={6} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
            <Typography
              component="div"
              variant="body2"
              align="center"
              sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
              {serviceContent.howGood.text4}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2, border: '1px solid #BDBDBD' }} />
        <Box sx={{ backgroundColor: '#FFFEF7' }}>
          <img src={linkObsImage.service.mobiles.phoneRecommendM} width={'100%'} />
          <Box mb={6}>
            <ButtonContent type={TypeScreen.MOBILE} />
          </Box>
          <img src={linkObsImage.service.mobiles.phoneWebSSA05sM} width={'100%'} style={{ paddingBottom: '20px' }} />
          <img src={linkObsImage.service.mobiles.phoneWebOPPOA3M} width={'100%'} style={{ paddingBottom: '20px' }} />
          <img src={linkObsImage.service.mobiles.phoneWebOPPOA3xM} width={'100%'} style={{ paddingBottom: '20px' }} />
          <img
            src={linkObsImage.service.mobiles.phoneWebOPPOA3Pro5GM}
            width={'100%'}
            style={{ paddingBottom: '20px' }}
          />
          <img src={linkObsImage.service.mobiles.phoneWebVIVOY19sM} width={'100%'} style={{ paddingBottom: '20px' }} />
          <img src={linkObsImage.service.mobiles.phoneWebM} width={'100%'} style={{ paddingBottom: '20px' }} />
        </Box>
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              fontWeight: 400,
              fontSize: 12,
              whiteSpace: 'pre-line',
              backgroundColor: '#085103',
              color: '#ffffff',
              padding: '20px',
            }}>
            {serviceContent.remark}
          </Typography>
        </Box>
        <Box>
          <ApplianceContentBoxMobile skuContent={daikinMaxInverter} />
          <ApplianceContentBoxMobile skuContent={tclNewMiracle} />
          <ApplianceContentBoxMobile skuContent={toshibaTopload} />
          <ApplianceContentBoxMobile skuContent={mitsubishiFlatDesign} />
        </Box>
      </BoxMobile>
      <Box id="box-faq" m="10%" mt="-4%">
        <FAQBoxContent />
      </Box>
    </>
  );
}
