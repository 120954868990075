import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CarousalImage from '../commons/ui/carousal-image';
import { ArrowRightAlt } from '@mui/icons-material';
import { Box } from '@mui/system';
import { linkObsImage } from '../../contentData/image-obs-link';

const btnStyle = {
  marginTop: '2em',
  fontWeight: 700,
  color: '#FFFFFF',
  width: '45%',
  border: '2px solid #0d7f42',
  background: '#0d7f42',
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

export default function HomeJuneRelease() {
  const heightBox = window.innerHeight - 227 || 500;

  const images = [linkObsImage.home.banner1, linkObsImage.home.banner2, linkObsImage.home.banner3];

  return (
    <>
      <Box
        sx={{
          minHeight: heightBox,
          ...boxStyle,
        }}>
        <Card sx={{ minHeight: 500, minWidth: 1200, maxWidth: 1230 }}>
          <CardContent sx={{ p: 0, mb: -5, minWidth: 1200 }}>
            <Grid container sx={{ p: 0, justifyContent: 'center', textAlign: 'left' }}>
              <Grid xs={4.3} sx={{ background: '#fafafa', minHeight: 500, p: 5 }}>
                <Typography component="div" variant="h3" sx={{ fontWeight: 700, color: '#0E7F42', mt: 4 }}>
                  ซีเจ ผ่อนได้ <br /> สบายกระเป๋า
                </Typography>

                <Box sx={{ borderBottom: '2px solid #0d7f42', mt: 2, width: '75%' }}></Box>
                <Typography component="div" variant="body1" mt={2}>
                  ผ่อนมือถือและเครื่องใช้ไฟฟ้าด้วยบัตรประชาชนใบเดียว
                </Typography>

                <Typography component="div" variant="body1">
                  ได้แล้วที่ CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้!
                </Typography>

                <Button
                  endIcon={<ArrowRightAlt />}
                  variant="contained"
                  color="success"
                  component={Link}
                  to="/services"
                  style={btnStyle}>
                  อ่านเพิ่มเติม
                </Button>
              </Grid>
              <Grid xs={7.7} pl={7.5} pr={9} pt="3%">
                <Box
                  sx={{
                    minHeight: '50vh',
                    ...boxStyle,
                  }}>
                  <CarousalImage urlImage={images} />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
